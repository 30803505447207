<template>
    <v-card
        class="mx-auto quiz-score-content grey--text text--darken-2 overflow-hidden"
        max-width="350"
        :loading="loading"
    >
        <div class="d-flex flex-row justify-space-between align-end">
            <v-card-title class="font-size-14 py-0">{{ $t('home.quiz_card_title') }}</v-card-title>
            <v-card-subtitle class="font-size-12 py-0 text-capitalize">{{
                $moment().format('MMM D, YYYY')
            }}</v-card-subtitle>
        </div>

        <v-divider />
        <div v-if="resultData && resultData.dataSets.length" class="overflow-hidden">
            <TierBar
                :title="`${$t('tier')} ${resultData.dataSets[0].tier}`"
                :description="`${resultData.dataSets[0].count} ${$t('students')} - ${
                    resultData.dataSets[0].percent.toFixed(2)
                }%`"
                color-class="accent"
                font-color="#fff"
                :value="`61% - 100%`"
            />
            <TierBar
                :title="`${$t('tier')} ${resultData.dataSets[1].tier}`"
                :description="`${resultData.dataSets[1].count} ${$t('students')} - ${
                    resultData.dataSets[1].percent.toFixed(2)
                }%`"
                color-class="warning"
                font-color="#000"
                :value="`41% - 60%`"
            />
            <TierBar
                :title="`${$t('tier')} ${resultData.dataSets[2].tier}`"
                :description="`${resultData.dataSets[2].count} ${$t('students')} - ${
                    resultData.dataSets[2].percent.toFixed(2)
                }%`"
                color-class="primary"
                font-color="#fff"
                :value="`0% - 40%`"
            />
        </div>
        <div v-else>
            <v-card-text class="text-center">{{ $t('not_data') }}</v-card-text>
        </div>
    </v-card>
</template>

<script>
import TierBar from './core/TierBar'
export default {
    name: 'QuizScoreCard',
    props: {
        tierData: {
            type: Array,
            default: () => [],
        },
        loading: { type: Boolean, default: false },
        filterDay: { type: Number, require: true },
    },
    components: {
        TierBar,
    },
    computed: {
        resultData() {
            return this.tierData.find(({ numberOfDays }) => numberOfDays === this.filterDay)
        },
    },
}
</script>

<style scoped>
.quiz-score-content {
    width: 100vh;
    box-shadow: -4px 2px 11px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    overflow: hidden !important;
}
</style>
