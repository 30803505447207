<template>
    <v-container class="overflow-hidden">
        <v-card
            class="mx-auto quiz-score-content grey--text text--darken-2 overflow-hidden"
            max-width="350"
            :loading="loading"
        >
            <div class="d-flex flex-row justify-space-between align-end">
                <v-card-title class="font-size-14 py-0">{{
                    $t('home.initial_test_card')
                }}</v-card-title>
                <v-card-subtitle class="font-size-12 py-0 text-capitalize" v-if="resultData">{{
                    $moment(`${resultData.initialDate}`).format('MMM D, YYYY')
                }}</v-card-subtitle>
            </div>
            <v-divider />
            <div v-if="resultData && resultData.initialDataSets.length" class="overflow-hidden">
                <TierBar
                    :title="`${$t('tier')} ${resultData.initialDataSets[0].tier}`"
                    :description="`${resultData.initialDataSets[0].count} ${$t('students')} - ${
                        resultData.initialDataSets[0].percent.toFixed(2)
                    }%`"
                    color-class="accent"
                    font-color="#fff"
                    value="61% - 100%"
                />
                <TierBar
                    :title="`${$t('tier')} ${resultData.initialDataSets[1].tier}`"
                    :description="`${resultData.initialDataSets[1].count} ${$t('students')} - ${
                        resultData.initialDataSets[1].percent.toFixed(2)
                    }%`"
                    color-class="warning"
                    font-color="#000"
                    value="41% - 60%"
                />
                <TierBar
                    :title="`${$t('tier')} ${resultData.initialDataSets[2].tier}`"
                    :description="`${resultData.initialDataSets[2].count} ${$t('students')} - ${
                        resultData.initialDataSets[2].percent.toFixed(2)
                    }%`"
                    color-class="primary"
                    font-color="#fff"
                    value="0% - 40%"
                />
            </div>
            <div v-else>
                <v-card-text class="text-center">{{ $t('not_data') }}</v-card-text>
            </div>
        </v-card>
        <v-card
            class="mx-auto quiz-score-content mt-3 grey--text text--darken-2"
            max-width="350"
            :loading="loading"
        >
            <div class="d-flex flex-row justify-space-between align-end">
                <v-card-title class="font-size-14 py-0">{{
                    $t('home.lastest_test_card')
                }}</v-card-title>
                <v-card-subtitle class="font-size-12 py-0 text-capitalize" v-if="resultData">{{
                    $moment(`${resultData.latestDate}`).format('MMM D, YYYY')
                }}</v-card-subtitle>
            </div>
            <v-divider />
            <div v-if="resultData && resultData.latestDataSets.length" class="overflow-hidden">
                <TierBar
                    :title="`${$t('tier')} ${resultData.latestDataSets[0].tier}`"
                    :description="`${resultData.latestDataSets[0].count} ${$t('students')} - ${
                        resultData.latestDataSets[0].percent.toFixed(2)
                    }%`"
                    color-class="accent"
                    font-color="#fff"
                    value="61% - 100%"
                />
                <TierBar
                    :title="`${$t('tier')} ${resultData.latestDataSets[1].tier}`"
                    :description="`${resultData.latestDataSets[1].count} ${$t('students')} - ${
                        resultData.latestDataSets[1].percent.toFixed(2)
                    }%`"
                    color-class="warning"
                    font-color="#000"
                    value="41% - 60%"
                />
                <TierBar
                    :title="`${$t('tier')} ${resultData.latestDataSets[2].tier}`"
                    :description="`${resultData.latestDataSets[2].count} ${$t('students')} - ${
                        resultData.latestDataSets[2].percent.toFixed(2)
                    }%`"
                    color-class="primary"
                    font-color="#fff"
                    value="0% - 40%"
                />
            </div>
            <div v-else>
                <v-card-text class="text-center">{{ $t('not_data') }}</v-card-text>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import TierBar from './core/TierBar'
export default {
    name: 'PlacementCard',
    props: {
        tierData: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
        filterDay: { type: Number, require: true },
    },
    components: {
        TierBar,
    },
    computed: {
        resultData() {
            return this.tierData.find(({ numberOfDays }) => numberOfDays === this.filterDay)
        },
    },
}
</script>

<style scoped>
.quiz-score-content {
    width: 100vh;
    box-shadow: -4px 2px 11px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
}
</style>
