<template>
    <v-container>
        <vue-headful :title="$t('title_home')" description="title" />
        <v-container>
            <v-row class="w-100 px-0 d-flex">
                <v-col cols="8" class="px-0 mr-auto" style="height: 60px">
                    <BeeTabs @changeTab="changeTab" :items="items" tab-color="secondary" />
                </v-col>
                <v-col cols="4" class="px-0 ml-auto d-flex">
                    <v-btn-toggle
                        color="primary"
                        class="ml-auto"
                        v-model="currentTime"
                        rounded
                        group
                    >
                        <v-btn
                            class="mx-2 border-radius-5 button-option"
                            active-class="button-option-active"
                            v-for="(day, index) of days"
                            small
                            :key="index"
                            :value="day.value"
                        >
                            <span>{{ day.name }}</span>
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row cols="12" class="position-relative">
                <div
                    class="position-absolute select-class d-flex justify-center align-start flex-column"
                >
                    <v-select
                        item-value="id"
                        item-text="name"
                        :items="courses"
                        :label="$t('choose_course')"
                        v-model="currentClass"
                        dense
                        outlined
                        flat
                        hide-details
                        color="primary"
                        @change="getTierDataByCourse"
                    ></v-select>
                    <span class="text-body-2 text-capitalize font-weight-bold my-2">{{
                        count_students
                    }}</span>
                </div>
                <SchoolDashboard />
                <div
                    class="position-absolute cards-data d-flex justify-center align-start flex-column opacity-9"
                    v-if="currentTab === 0"
                >
                    <QuizScoreCard
                        :loading="loadingData"
                        :tier-data="totalData.quizDataSets"
                        :filter-day="currentTime"
                    />
                </div>
                <div
                    class="position-absolute cards-data d-flex justify-center align-start flex-column opacity-9"
                    v-if="currentTab === 1"
                >
                    <PlacementCard
                        :loading="loadingData"
                        :tier-data="totalData.placementTestDataSets"
                        :filter-day="currentTime"
                    />
                </div>
                <div
                    class="position-absolute cards-data d-flex justify-center align-start flex-column opacity-9"
                    v-if="currentTab === 2"
                >
                    <ComprehesionCard
                        :loading="loadingData"
                        :comprehesion-data="totalData.comprehensionDataSets"
                        :filter-day="currentTime"
                    />
                </div>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'

import tierStats from '../../services/tierStats'

import SchoolDashboard from '../../components/home/SchoolDashboard'
import QuizScoreCard from '../../components/home/QuizScoreCard'
import PlacementCard from '../../components/home/PlacementCard'
import ComprehesionCard from '../../components/home/ComprehesionCard'

export default {
    name: 'Home',
    components: {
        SchoolDashboard,
        QuizScoreCard,
        PlacementCard,
        ComprehesionCard,
    },
    data: () => ({
        currentTab: 0,
        currentClass: null,
        currentTime: 90,
        loadingData: false,
        totalData: {},
    }),
    mounted() {
        if (this.courses.length) {
            this.currentClass = this.courses[0].id
            this.getTierDataByCourse()
        }
    },
    methods: {
        changeTab(tab) {
            this.currentTab = tab
        },
        async getTierDataByCourse() {
            this.loadingData = !this.loadingData
            const response = await tierStats
                .getTierData(this.currentClass)
                .catch((error) => console.error(error))
            console.debug(response.content)
            this.totalData = response.content
            this.loadingData = !this.loadingData
        },
    },
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
        }),
        items() {
            return [
                this.$t('home.quiz_score'),
                this.$t('home.placement_test'),
                this.$t('home.compreshion_skills'),
            ]
        },
        count_students() {
            return this.totalData.studentsCount
                ? `${this.totalData.studentsCount} ${this.$t('students')}`
                : ''
        },
        days() {
            return [
                { name: this.$t('stats.seven_days'), value: 7 },
                { name: this.$t('stats.thirty_days'), value: 30 },
                { name: this.$t('stats.ninety_days'), value: 90 },
            ]
        },
    },
}
</script>

<style scoped>
.select-class {
    z-index: 1;
    top: 1vh;
    left: 1vh;
}
.button-option-active {
    border: 2px solid #514773 !important;
}

.button-option {
    color: #ccc8d9;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid #ccc8d9;
    padding: 0 3vh !important;
}
.cards-data {
    top: 1vh;
    right: 1vh;
}
</style>
