import Services from './base-services'

const service = new Services('tierStats')

export default {
    getTierData(courseId: number) {
        return service.get(`getTierData?courseId=${courseId}`)
    },
    getProgressSkillByCourseId(courseId: number, teacherId: string | number) {
        return service.get(`getProgressSkillByCourseId?courseId=${courseId}&teacherId=${teacherId}`)
    },
    getSubSkillAccuracyByCourseId(code: string, courseId: number, courses: Array<number>) {
        return service.get(
            `getSubSkillAccuracyByCourseId?code=${code}&courseId=${courseId}&courses=${courses}`
        )
    },
}
