var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto quiz-score-content grey--text text--darken-2 overflow-hidden",attrs:{"max-width":"350","loading":_vm.loading}},[_c('div',{staticClass:"d-flex flex-row justify-space-between align-end"},[_c('v-card-title',{staticClass:"font-size-14 py-0"},[_vm._v(_vm._s(_vm.$t('home.quiz_card_title')))]),_c('v-card-subtitle',{staticClass:"font-size-12 py-0 text-capitalize"},[_vm._v(_vm._s(_vm.$moment().format('MMM D, YYYY')))])],1),_c('v-divider'),(_vm.resultData && _vm.resultData.dataSets.length)?_c('div',{staticClass:"overflow-hidden"},[_c('TierBar',{attrs:{"title":`${_vm.$t('tier')} ${_vm.resultData.dataSets[0].tier}`,"description":`${_vm.resultData.dataSets[0].count} ${_vm.$t('students')} - ${
                _vm.resultData.dataSets[0].percent.toFixed(2)
            }%`,"color-class":"accent","font-color":"#fff","value":`61% - 100%`}}),_c('TierBar',{attrs:{"title":`${_vm.$t('tier')} ${_vm.resultData.dataSets[1].tier}`,"description":`${_vm.resultData.dataSets[1].count} ${_vm.$t('students')} - ${
                _vm.resultData.dataSets[1].percent.toFixed(2)
            }%`,"color-class":"warning","font-color":"#000","value":`41% - 60%`}}),_c('TierBar',{attrs:{"title":`${_vm.$t('tier')} ${_vm.resultData.dataSets[2].tier}`,"description":`${_vm.resultData.dataSets[2].count} ${_vm.$t('students')} - ${
                _vm.resultData.dataSets[2].percent.toFixed(2)
            }%`,"color-class":"primary","font-color":"#fff","value":`0% - 40%`}})],1):_c('div',[_c('v-card-text',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('not_data')))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }