<template>
    <v-container class="overflow-hidden">
        <v-card
            class="mx-auto quiz-score-content grey--text text--darken-2 pb-3 overflow-hidden"
            max-width="350"
            :loading="loading"
        >
            <v-card-title class="font-size-14 py-1">{{
                $t('home.highest_comprehesion_card')
            }}</v-card-title>
            <v-divider />
            <div v-if="resultData && resultData.highest">
                <SkillBar
                    :title="$t('home.comprehesion_skills[0]')"
                    :value="resultData.highest.length ? resultData.highest[0].percent : 0"
                    title-color="#24dca9"
                    color="#24dca9"
                    class="px-4"
                />

                <SkillBar
                    :title="$t('home.comprehesion_skills[1]')"
                    :value="resultData.highest.length ? resultData.highest[1].percent : 0"
                    color="#24dca9"
                    title-color="#24dca9"
                    class="px-4"
                />

                <SkillBar
                    :title="$t('home.comprehesion_skills[2]')"
                    :value="resultData.highest.length ? resultData.highest[2].percent : 0"
                    color="#24dca9"
                    title-color="#24dca9"
                    class="px-4"
                />
            </div>
            <div v-else>
                <v-card-text class="text-center">{{ $t('not_data') }}</v-card-text>
            </div>
        </v-card>
        <v-card
            class="mx-auto quiz-score-content mt-3 grey--text text--darken-2 pb-3"
            max-width="350"
            :loading="loading"
        >
            <v-card-title class="font-size-14 py-1">{{
                $t('home.lowest_comprehesion_card')
            }}</v-card-title>
            <v-divider />
            <div v-if="resultData && resultData.lowest">
                <SkillBar
                    :title="$t('home.comprehesion_skills[0]')"
                    :value="resultData.lowest.length ? resultData.lowest[0].percent : 0"
                    color="#8320FD"
                    class="px-4"
                />
                <SkillBar
                    :title="$t('home.comprehesion_skills[1]')"
                    :value="resultData.lowest.length ? resultData.lowest[1].percent : 0"
                    color="#8320FD"
                    class="px-4"
                />

                <SkillBar
                    :title="$t('home.comprehesion_skills[2]')"
                    :value="resultData.lowest.length ? resultData.lowest[2].percent : 0"
                    color="#8320FD"
                    class="px-4"
                />
            </div>
            <div v-else>
                <v-card-text class="text-center">{{ $t('not_data') }}</v-card-text>
            </div>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'ComprehesionCard',
    props: {
        comprehesionData: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
        filterDay: { type: Number, require: true },
    },
    computed: {
        resultData() {
            return this.comprehesionData.find(({ numberOfDays }) => numberOfDays === this.filterDay)
        },
    },
}
</script>

<style scoped>
.quiz-score-content {
    width: 100vh;
    box-shadow: -4px 2px 11px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
}
</style>
