<template>
    <v-row :class="`position-absolute d-flex justify-center ${positionClass}`">
        <v-row
            class="d-flex justify-space-around align-center px-3 flex-row tier-content white border-radius-1 text-capitalize text-center"
        >
            <v-col cols="4" class="font-size-12">{{ title }}</v-col>
            <v-col
                cols="5"
                :class="`${barColorClass ? barColorClass : ''} pa-0 ma-3 text-center font-size-12`"
                :style="{ color: fontColor, backgroundColor: barColorStyle }"
                >{{ value }}</v-col
            >
        </v-row>
    </v-row>
</template>

<script>
export default {
    name: 'TierBubble',
    props: {
        title: { type: String, default: '', required: true },
        value: { type: String, default: '', required: true },
        positionClass: { type: String, default: '', required: true },
        barColorClass: { type: String, default: 'primary' },
        barColorStyle: { type: String, default: '' },
        fontColor: { type: String, default: '#000' },
    },
}
</script>

<style scoped>
.tier-content {
    contain: initial;
    overflow: visible;
    width: 30vh;
    box-sizing: border-box;
    border: 1px solid #c0b8d3;
    box-shadow: -4px 5px 9px 0 rgba(0, 0, 0, 0.06);
    opacity: 0.89;
}

.tier-content::after {
    position: absolute;
    content: '';
    bottom: -5vh;
    transform: translateY(-100%);
    width: 15px;
    height: 20px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid #fff;
    z-index: 2;
}
</style>
