<template>
    <div class="d-flex justify-start align-center flex-row">
        <div class="py-0">
            <v-card-text class="py-0 text-capitalize font-size-12">{{ title }}</v-card-text>
        </div>
        <div
            :class="`${
                colorClass ? colorClass : 'primary'
            } py-1 px-2 ma-3 text-center font-size-12 w-30`"
            :style="{ backgroundColor: color, color: fontColor }"
        >
            {{ value }}
        </div>
        <div class="text-body-1 py-1 mx-1 text-center font-weight-bold">
            <span class="font-size-10">{{ description }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TierBar',
    props: {
        title: { type: String, default: '', required: true },
        description: { type: String, default: '', required: true },
        color: { type: String, default: '' },
        colorClass: { type: String, default: '' },
        fontColor: { type: String, default: '' },
        value: { type: String, default: '', required: true },
    },
}
</script>

<style scoped>
</style>
