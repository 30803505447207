<template>
    <v-container class="school-dashboard-background position-relative">
        <tier-bubble
            :title="`${$t('tier')} 3`"
            :value="'0% - 40%'"
            position-class="tier-3"
            bar-color-class="primary"
            font-color="#fff"
        />
        <tier-bubble
            :title="`${$t('tier')} 2`"
            :value="'41% - 60%'"
            position-class="tier-2"
            bar-color-class="warning"
        />
        <tier-bubble
            :title="`${$t('tier')} 1`"
            :value="'61% - 100%'"
            position-class="tier-1"
            bar-color-class="accent"
            font-color="#fff"
        />
    </v-container>
</template>

<script>
import TierBubble from './core/TierBubble'
export default {
    name: 'SchoolDashboard',
    components: {
        'tier-bubble': TierBubble,
    },
    data: () => ({
        showMenu: true,
    }),
}
</script>

<style scoped>
.school-dashboard-background {
    width: 100%;
    min-height: 820px;
    background-image: url('../../assets/img/home/dashboard-001.svg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .tier-content::after {
        bottom: -7vh;
    }
    .tier-3 {
        top: 27.5%;
        left: 38.5%;
    }
    .tier-1 {
        top: 43%;
        left: 18%;
    }

    .tier-2 {
        top: 54%;
        left: 49.5%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .tier-content::after {
        bottom: -7vh;
    }
    .tier-3 {
        top: 22%;
        left: 42.5%;
    }
    .tier-1 {
        top: 38%;
        left: 24.5%;
    }

    .tier-2 {
        top: 50%;
        left: 52.5%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .tier-content::after {
        bottom: -5vh;
    }
    .tier-3 {
        top: 26%;
        left: 40%;
    }
    .tier-1 {
        top: 42%;
        left: 25%;
    }

    .tier-2 {
        top: 53%;
        left: 48%;
    }
}
</style>
